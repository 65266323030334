<template>
  <div class="workout-library">
    <h3 class="title is-5">{{ $t('workout.library' )}}</h3>
    <div v-show="false" class="workout-search">
      <input type="text" v-model="workoutSearch"/>
      <label>{{ $t('general.tag') }}: </label><select v-model="tagSearch" name="tag">
        <option value=""></option>
        <option value="recovery">{{ $t('workout.recovery') }}</option>
        <option value="endurance">{{ $t('workout.endurance') }}</option>
        <option value="tempo">{{ $t('workout.tempo') }}</option>
        <option value="threshold">{{ $t('workout.threshold') }}</option>
        <option value="vo2max">{{ $t('workout.vo2max') }}</option>
        <option value="anaerobic">{{ $t('workout.anaerobic') }}</option>
        <option value="sprint">{{ $t('workout.sprint') }}</option>
        <option value="other">{{ $t('workout.other') }}</option>
      </select>
      <button v-on:click="search">{{ $t('general.search') }}</button>
    </div>
    <button class="button" v-on:click="createWorkout()">{{ $t('workout.create_workout') }}</button>

    <div class="workout-list">
      <b-tabs position="is-centered" class="block" :animated="false">
        <b-tab-item :label="$t('workout.your_workouts')">
          <ul class="workouts">
            <li v-for="(workout, index) in workouts" v-bind:key="index">
              <WorkoutSummary v-bind:workout="workout" :actionsEnabled="true"
               @delete_workout="removeWorkout" @select="select(workout)" :class="selected==workout && 'selected'"/>
            </li>
          </ul>
        </b-tab-item>

        <b-tab-item :label="$t('workout.vo2fast_workouts')">
          <MustSubscribe :message="$t('subscription.subscribe_to_get_vo2_workouts')">
            <div class="vo2fastWorkouts">
              <b-collapse v-for="(category, i) in Object.entries(vo2fastWorkouts)" :key="i" class="card" animation="slide"
                :open="isOpen === i" @open="isOpen = i">
                <template #trigger="props">
                  <div class="card-header" role="button">
                    <p class="card-header-title">{{ category[0] }}</p>
                    <a class="card-header-icon"><b-icon :icon="props.open ? 'chevron-down' : 'chevron-up'"/></a>
                  </div>
                </template>

                <ul class="card-content">
                  <li v-for="(workout, j) in category[1]" :key="j">
                    <WorkoutSummary v-bind:workout="workout" :actionsEnabled="true" :editionsEnabled="false"
                     @select="select(workout)" :class="selected==workout && 'selected'"/>
                  </li>
                </ul>
              </b-collapse>
            </div>
          </MustSubscribe>
        </b-tab-item>
      </b-tabs>
    </div>

    <div v-if="isSelectionEnabled" class="selectdiv">
      <button class="button" @click="emitSelection">{{ $t('general.select') }}</button>
    </div>
  </div>
</template>

<script>
import WorkoutSummary from './WorkoutSummary.vue';
const workoutUtil = require('@/../shared/workout/workoutUtil.js');
import MustSubscribe from '@/components/MustSubscribe.vue';

export default{
  name: 'workout-library',
  props: ['selectionEnabled'],
  computed: {
    isSelectionEnabled(){ return this.selectionEnabled === true; }
  },
  data(){
    return {
      workoutSearch: '',
      tagSearch: '',
      workouts: [],
      vo2fastWorkouts: {},
      selected: null,
      isOpen: -1,
    };
  },
  methods: {
    //TODO: Load VO2Fast workouts category on category open
    async loadVO2FastWorkouts(){
      this.vo2fastWorkouts = await this.get('/workout_library/vo2fast_workouts');
      let entries = Object.entries(this.vo2fastWorkouts);
      for(let [categoryName, category] of entries){
        for(let workout of category){
          this.$set(workout, 'data', workoutUtil.calcData(workout, this.ugetSetModel(this.LocalDate.now())));
          workout.data.category = categoryName;
        }
      }
    },

    async loadWorkouts(){
      try{
        this.workouts = await this.get('/workout_library/workouts');
        for(let workout of this.workouts)
          workout.data = workoutUtil.calcData(workout, this.ugetSetModel(this.LocalDate.now()));

        await this.loadVO2FastWorkouts();

      }catch(err){
        console.error(err);
      }
    },

    removeWorkout(workout){
      this.workouts.splice(this.workouts.indexOf(workout),1);
    },

    search(){

    },

    createWorkout(){
      this.$eventBus.$emit('close-modal-plan-workout');
      this.$eventBus.$emit('close-modal-workout-library');
      this.$router.push({ name: 'workout-creator'});
    },

    emitSelection(){
      if(this.isSelectionEnabled && this.selected){
        this.$eventBus.$emit('workout-selected', this.selected);
        this.$eventBus.$emit('close-modal-workout-library');
      }
    },

    select(workout){
      if(this.isSelectionEnabled){
        if(this.selected == workout){
          this.selected = null;
        }else{
          this.selected = workout;
        }
      }
    }
  },
  mounted(){
    this.loadWorkouts();
  },
  components: {
    WorkoutSummary,
    MustSubscribe,
  },
}
</script>

<style>

.workout-list{
  width: 95%;
  height: 600px;
  overflow-y: auto;
  margin: auto;
  margin-top: 10px;
  border: solid grey 1px;
}

.workouts{
  list-style: none;
}

.selected{
  background-color: lightblue;
}
</style>
