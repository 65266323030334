<template>
  <div class="manage-billing">
    <router-link v-if="showSubButton && (!$store.getters.user.has_stripe_account || $store.getters.hasTrial || !$store.getters.hasStandardAccess)" to="/subscribe" tag="button" class="button is-primary">{{ $t('subscription.subscribe') }}</router-link><br/>
    <b-button class="mt-2" @click="manageBilling" v-if="$store.getters.user.has_stripe_account">{{ $t('subscription.manage_billing') }}</b-button>
  </div>
</template>


<script>

export default{
  name: 'ManageBilling',
  props: {
    showSubButton: { type: Boolean, default: false },
  },
  methods: {
    async manageBilling(){
      try{
        let redirect = await this.post('/create_portal_session', { return_url: window.location.href });
        window.location.href = redirect;
      }catch(err){
        console.error(err);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
