<template>
  <div id="syncs">
    <div id="garmin-connect">
      <h4 class="title is-5">Garmin</h4>
      <img class="syncicon" src="@/assets/syncs/GarminConnect.png" :title="$t('account.syncs.garmin_tooltip')"><br/>
      <button class="syncbtn" v-if="!syncs.garmin" @click="connectGarmin()">{{ $t('account.syncs.connect') }}</button>
      <button class="syncbtn unsyncbtn" v-if="syncs.garmin" @click="disconnectGarmin()" @mouseover="garminHover=true" @mouseout="garminHover=false">
        <span v-show="!garminHover">{{ $t('account.syncs.connected') }}</span><span v-show="garminHover">{{ $t('account.syncs.disconnect') }}</span>
      </button>
    </div>

    <div id="strava">
      <h4 class="title is-5">Strava</h4>
      <img v-if="!syncs.strava" class="stravabtn" @click="connectStrava()" src="@/assets/syncs/strava_btn.png"/>
      <button class="syncbtn unsyncbtn" v-if="syncs.strava" @click="disconnectStrava()" @mouseover="stravaHover=true" @mouseout="stravaHover=false">
        <span v-show="!stravaHover">{{ $t('account.syncs.connected') }}</span><span v-show="stravaHover">{{ $t('account.syncs.disconnect') }}</span>
      </button>
      <p class="has-text-accent">{{ $t('account.syncs.strava_limit') }}</p>
    </div>

    <!--<div id="wahoo">
      <h4 class="title is-5">Wahoo</h4>
      <img class="syncicon" src="@/assets/syncs/wahoo.png" :title="$t('account.syncs.wahoo_tooltip')"><br/>
      <button class="syncbtn" v-if="!syncs.wahoo" @click="connectWahoo()">{{ $t('account.syncs.connect') }}</button>
      <button class="syncbtn unsyncbtn" v-if="syncs.wahoo" @click="disconnectWahoo()" @mouseover="wahooHover=true" @mouseout="wahooHover=false">
        <span v-show="!wahooHover">{{ $t('account.syncs.connected') }}</span><span v-show="wahooHover">{{ $t('account.syncs.disconnect') }}</span>
      </button>
    </div>-->


    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false">
      <div class="box">
        <h1 class="title is-2"> {{ $t('account.syncs.disconnection')}} </h1>
        <section class="block">
          <b-icon icon="sync" size="is-large" custom-class="mdi-spin-reverse"/>
        </section>
      </div>
    </b-loading>
  </div>
</template>

<script>

export default{
  name: 'syncs',
  data(){
    return {
      garminHover: false,
      garminTrainingHover: false,
      stravaHover: false,
      wahooHover: false,
      isLoading: false,
    };
  },
  computed: {
    syncs(){ return this.$store.getters.user.syncs; }
  },
  methods: {
    connectGarmin(){
      this.post('/garmin/request_token').then(data => {
        localStorage.setItem('redirectSyncLink', this.$route.name);
        window.location.replace('https://connect.garmin.com/oauthConfirm?oauth_token='+data.oauth_token+'&oauth_callback='+process.env.VUE_APP_URL+'/sync_garmin');
      }).catch(err => { console.error(err); });
    },

    async disconnectGarmin(){
      this.isLoading = true;
      try{
        await this.post('/garmin/deregistrate');
        this.syncs.garmin = false;
      }catch(err){
        console.error(err);
        this.$buefy.toast.open({
          duration: 3000,
          message: this.$t(err),
          position: 'is-bottom',
          type: 'is-danger'
        });
      }
      this.isLoading = false;
    },

    connectStrava(){
      localStorage.setItem('redirectSyncLink', this.$route.name);
      window.location.replace('http://www.strava.com/oauth/authorize?client_id=54956&response_type=code&redirect_uri='+ process.env.VUE_APP_URL + '/sync_strava&approval_prompt=force&scope=activity:read_all,read');
    },

    async disconnectStrava(){
      this.isLoading = true;
      try{
        await this.post('/strava/deregistrate');
        this.syncs.strava = false;
      }catch(err){
        console.error(err);
        this.$buefy.toast.open({
          duration: 3000,
          message: this.$t(err),
          position: 'is-bottom',
          type: 'is-danger'
        });
      }
      this.isLoading = false;
    },

    connectWahoo(){
      localStorage.setItem('redirectSyncLink', this.$route.name);
      window.location.replace('https://api.wahooligan.com/oauth/authorize?client_id=sqM3D-eNtgi540CKWyxBr0LwhktXQ0bJa-Rffigc-nk&redirect_uri='+ process.env.VUE_APP_URL + '/sync_wahoo' + '&scope=user_read%20workouts_read%20offline_data%20plans_read%20plans_write&response_type=code');
    },

    async disconnectWahoo(){
      this.isLoading = true;
      try{
        await this.post('/wahoo/deregistrate');
        this.syncs.wahoo = false;
      }catch(err){
        console.error(err);
        this.$buefy.toast.open({
          duration: 3000,
          message: this.$t(err),
          position: 'is-bottom',
          type: 'is-danger'
        });
      }
      this.isLoading = false;
    },


  },
}
</script>

<style scoped>
.syncbtn{
  border: 1px solid;
  cursor: pointer;
  padding: 10px 30px;
  font-weight: 700;
  background-color: white;
  transition: 0.3s;
}

.unsyncbtn{
  opacity: 0.35;
}

.syncbtn:hover{
  background-color: #999999;
}

.unsyncbtn:hover{
  opacity: 1;
  background-color: white;
}

.syncicon{
  width: 64px;
}

.stravabtn{
  cursor: pointer;
}
</style>
