<template>
  <b-tooltip :label="$t('plan.'+this.trainingType)" position="is-top">
    <b-button class="training-type-picker" @click="changeType()">
      <b-icon v-if="trainingType === 'outdoor'" icon="bike"/>
      <img v-if="trainingType === 'indoor'" src="~@/assets/icons/hometrainericon24.png"/>
    </b-button>
  </b-tooltip>
</template>

<script>

const trainingTypes = ['outdoor', 'indoor'];

export default{
  name: 'TrainingTypePicker',
  props: ['value'],
  data(){
    return {
      trainingType: 'outdoor',
    };
  },
  methods: {
    changeType(){
      console.log(this.value);
      if(!this.trainingType)
        return this.trainingType = 'outdoor';

      //Get next training type in array
      this.trainingType = trainingTypes[(trainingTypes.indexOf(this.trainingType)+1)%trainingTypes.length];
      this.$emit('input', this.trainingType);
    },
  },
  mounted(){
    this.trainingType = this.value || 'outdoor';
  },
  watch: {
    value(v, ov){
      console.log(v, ov);
      this.trainingType = this.value;
    },
  }
};

</script>

<style lang="scss" scoped>
</style>
