<template>
  <b-field :label="label">
    <b-input v-model.number="displayValue" @change.native="updateValue" type="number" step="0.01"/> <span class="pl-1 pt-2"><b>{{ displayUnit(unit) }}</b></span>
  </b-field>
</template>

<script>

export default{
  name: 'unit-input',
  props: {
    value: { type: Number },
    unit: { type: String },
    label: { type: String },
  },
  data(){
    return {
      displayValue: this.value,
    };
  },
  methods: {
    getValue(){
      return this.convertUnit(this.displayValue, this.unit);
    },

    setValue(value){
      this.displayValue = this.convertUnitFrom(this.value, this.unit);
    },

    updateValue(){
      this.$emit('input', this.getValue());
    },
  },
  mounted(){
    this.setValue(this.value);
  },
  watch: {
    value(){
      this.setValue(this.value);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
