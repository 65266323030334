<!-- Time Input -->
<!-- html time input that return duration as number instead of a string -->
<template>
  <input class="input"  @change="updateValue" type="time" name="duration" v-model="time" step="1"/>
</template>

<script>
export default {
  name: 'time-input',
  props: ['value'],
  data() {
    return {
      time: this.value,
    };
  },
  methods: {
    getTime(){
      var split = this.time.split(':');
      return (parseInt(split[0]) || 0)*3600 + (parseInt(split[1]) || 0)*60 + (parseInt(split[2]) || 0);
    },

    setTime(value){
      var hours = Math.floor(value/3600);
      var minutes = Math.floor((value%3600)/60);
      var seconds = Math.floor(value%60);
      this.time = hours.toString().padStart(2,'0')+':'+minutes.toString().padStart(2,'0')+':'+seconds.toString().padStart(2,'0');
    },

    updateValue(){
      this.$emit('input', this.getTime());
    },
  },
  mounted(){
    this.setTime(this.value);
  },
  watch: {
    value(){
      this.setTime(this.value);
    },
  }
}
</script>

<style lang="scss" scoped>
</style>
