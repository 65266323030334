<template>
  <div class="must-subscribe">
    <div v-if="!hasAccess" class="has-text-centered">
      <h3 class="title is-4"><span v-html="getMessage"/> <b v-if="$store.getters.user.has_trial">{{ $t('subscription.days_free') }}</b></h3>
      <div class="mt-5" v-if="$store.getters.user.has_stripe_account && $store.getters.hasStandardAccess && access === 'PREMIUM'">
        <ManageBilling/>
      </div>
      <div class="mt-5" v-else>
        <b-button class="m-auto" type="is-primary" size="is-large" tag="router-link" to="/subscribe">
          {{ $t('subscription.subscribe') }}
        </b-button>
      </div>
    </div>

    <div v-if="hasAccess">
      <slot/>
    </div>
  </div>
</template>

<script>
import ManageBilling from './ManageBilling.vue';

export default{
  name: 'MustSubscribe',
  props: {
    message: String,
    access: {
      type: String,
      default: function(){ return 'STANDARD'; },
    },
  },
  computed: {
    getMessage(){
      if(this.message){
        return this.message;
      }

      if(this.access === 'PREMIUM'){
        if(this.$store.getters.hasStandardAccess)
          return this.$t('subscription.require_premium_subscription');
        return this.$t('subscription.subscribe_to_premium_access');
      }

      return this.$t('subscription.subscribe_to_standard_access');
    },
    hasAccess(){
      if(this.access === 'PREMIUM')
        return this.$store.getters.hasPremiumAccess;
      else if(this.access === 'STANDARD')
        return this.$store.getters.hasStandardAccess;

      console.error('Unknown required Access: ', this.access);
      return true;
    },
  },
  components: {
    ManageBilling,
  },
};
</script>
