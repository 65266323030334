<!-- Helper component -->
<!-- Question icon that will display a text when hovered/clicked on -->
<template>
  <span>
    <span v-if="isTooltip">
      <b-tooltip multilined type="is-dark" :size="$root.isMobile() ? 'is-medium' : 'is-large'" :triggers="['click','hover']" :position="position"
      :auto-close="true">
        <template v-slot:content>
          <slot name="content"><p v-html="label"/></slot>
        </template>
        <b-icon icon="help-circle"/>
      </b-tooltip>
    </span>

    <span v-else>
      <span class="is-clickable" @click="showHelpModal"><b-icon icon="help-circle"/></span>
    </span>
    <b-modal v-model="showHelp">
      <template v-slot:content>
        <slot name="content"><p v-html="label"/></slot>
      </template>
    </b-modal>
  </span>
</template>

<script>

export default{
  name: 'helper',
  props: {
    label: { type: String, default: ''},
    isModal: { type: Boolean, default: false },
    isModalMobile: { type: Boolean, default: false },
    position: { type: String, default: 'is-top' },
  },
  computed: {
    isTooltip(){ return !(this.isModal || (this.isModalMobile && (this.$root.isTablet() || this.$root.isMobile())));}
  },
  data(){
    return{
      showHelp: false,
    };
  },
  methods: {
    showHelpModal(){
      this.showHelp = true;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
