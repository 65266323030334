<template>
  <div class="workout-summary" @click.prevent="$emit('select')">
    <WorkoutViewer :workout="workout" :showAxis="false" class="workout-view"/>
    <div class="workout-data">
      <div class="left">
        <h4 class="title is-5">{{ workout.name }}</h4>
        <h5 class="title is-6">{{ utimeToString(workout.duration, true) }}</h5>
      </div>
      <div class="center">
          <p v-if="workout.data && workout.data.load"><b>{{ $t('metrics.load') }}:</b> {{ workout.data.load }}</p>
          <p v-if="workout.data && workout.data.intensity"><b>{{ $t('metrics.intensity') }}:</b> {{ workout.data.intensity }}</p>
          <p v-if="workout.data && workout.data.level"><b>Level: </b> {{ workout.data.level }}</p>
      </div>
    </div>
    <div v-if="actionsEnabled" class="workout-action">
      <button v-if="editionsEnabled" class="button" :title="$t('workout.edit_workout')" @click="editWorkout()"><b-icon icon="pencil"/></button>
      <button class="button" :title="$t('workout.clone_workout')" @click="cloneWorkout()"><b-icon icon="content-copy"/></button>
      <button v-if="editionsEnabled" class="button" :title="$t('workout.delete_workout')" @click="deleteWorkout()"><b-icon icon="delete"/></button>
    </div>
  </div>
</template>

<script>
import WorkoutViewer from './WorkoutViewer.vue';
const workoutUtil = require('@/../shared/workout/workoutUtil.js');


export default{
  name: 'workout-summary',
  props: {
    workout: {
      type: Object,
      required: true,
    },
    actionsEnabled: {
      type: Boolean,
      default: false,
    },
    editionsEnabled: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    user(){ return this.$store.getters.user; },
  },
  data(){
    return {

    };
  },

  methods: {
    editWorkout(){
      this.$router.push({ name: 'workout-creator', params : { workout: this.workout } });
    },

    cloneWorkout(){
      var workoutClone = JSON.parse(JSON.stringify(this.workout));
      workoutClone._id = null;
      workoutClone.owner = null;
      this.$router.push({ name: 'workout-creator', params : { workout: workoutClone } });
    },

    deleteWorkout(){
      this.post('/workout_library/delete_workout', { workout_id: this.workout._id }).then(() => {
        this.$emit('delete_workout', this.workout);
      }).catch(err => {
        console.error(err);
      });
    },

  },
  components: {
    WorkoutViewer,
  }
}
</script>

<style scoped>
.workout-summary{
  display: flex;
  height: 90px;
  width: 100%;
}

.workout-view{
  height: 100%;
  width: 30%;
  flex: 1;
}

.workout-data{
  flex: 2;
  display: flex;
  justify-content: space-evenly;
}

.workout-action{
  flex: 1;
}
</style>
